import axios from '@axios';

export default {
  namespaced: true,
  state: {
    notifications: [],
  },
  getters: {},
  mutations: {
  },
  actions: {
    fetchNotifications({ ctx }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/notifications')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    // Action to mark a single notification as read
    markNotificationAsRead({ ctx }, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/notifications/${id}/mark-as-read`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    // Action to mark all notifications as read
    markAllNotificationsAsRead({ ctx }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/notifications/mark-all-as-read')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
