<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="notifications.filter(notification => notification.is_read === 0).length"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          الإشعارات
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ notifications.filter(notification => notification.is_read === 0).length }} جديدة
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-link
        v-for="notification in notifications"
        :key="notification.id"
        :href="notification.link"
        @click.native="markAsRead(notification)"
        target="_blank"
        rel="noopener noreferrer"
      >
        <b-media  :class="{ 'notification-unread': notification.is_read === 0 }"        >
          <template #aside>
            <feather-icon
                :icon="getAvatarSrc(notification.type)"
                size="32"
            />
          </template>
          <div class="media-body">
            <p class="media-heading">
              <span class="font-weight-bolder">
                {{ notification.title }}
              </span>
            </p>
            <small class="notification-text">{{ notification.message }}</small>
            <p>
              <small class="notification-text">{{ formatDate(notification.created_at) }}</small>
            </p>
          </div>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        :to="{ name: 'notifications' }"
      >
        قراءة كل الإشعارات
      </b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import store from '@/store';
import notificationStore from '@/views/modules/notification/notificationStore';
import { onUnmounted, ref } from '@vue/composition-api';
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }
    // Mark individual notification as read
    const markAsRead = (notification) => {
      store
        .dispatch('app-notification/markNotificationAsRead', notification.id)
        .then(() => {
          notification.is_read = 1;
        })
        .catch((error) => {
          console.error('Error marking notification as read:', error);
        });
    };

    // Mark all notifications as read
    const markAllAsRead = () => {
      store
        .dispatch('app-notification/markAllNotificationsAsRead')
        .then(() => {
          notifications.value.forEach((notification) => {
            notification.is_read = 1;
          });
        })
        .catch((error) => {
          console.error('Error marking all notifications as read:', error);
        });
    };

    // Get the avatar src based on notification type
    const getAvatarSrc = (type) => {
      switch (type) {
        case 'evaluation_assigned':
        return "AlertCircleIcon";
        case 'evaluation_completed':
          return "CheckCircleIcon";
        case 'user_mentioned':
        return "UserIcon";
        default:
        return "UserIcon";
      };
    }
 
    // Format the notification date
    const formatDate = (date) => {
      return new Date(date).toLocaleDateString('ar-EG', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    };
    const NOTIFICATION_APP_STORE_MODULE_NAME = 'app-notification';
    // Register module
    if (!store.hasModule(NOTIFICATION_APP_STORE_MODULE_NAME)) store.registerModule(NOTIFICATION_APP_STORE_MODULE_NAME, notificationStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(NOTIFICATION_APP_STORE_MODULE_NAME)) store.unregisterModule(NOTIFICATION_APP_STORE_MODULE_NAME);
    });
    const notifications = ref([]);
    store.dispatch('app-notification/fetchNotifications').then((res) => {
      notifications.value = res.data.notifications;
    });

    return {
      perfectScrollbarSettings,
      notifications,
      markAsRead,
      markAllAsRead,
      formatDate,
      getAvatarSrc,
    }
  },
}
</script>
<style scoped>
.notification-unread {
   background-color: #f0f4ff;
}
/* Link styling */
.notification-item a {
  display: block;
  color: inherit;
  text-decoration: none;
}
</style>
