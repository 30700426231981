<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>
    <b-navbar-nav class="nav align-items-center ml-auto">
      <dark-Toggler class="d-none d-lg-block" />
      <notification-dropdown  />
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ userData.name }}
            </p>
            <span class="user-status"> {{ $t(userData.role) }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :text="userData.name.substring(0, 1)"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="account"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>حسابي</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>تسجيل خروج</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BAlert,
} from 'bootstrap-vue';
import { VAlert } from 'vue';
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue';
import { initialAbility } from '@/libs/acl/config';
import useJwt from '@/auth/jwt/useJwt';
import NotificationDropdown from '@/@core/layouts/components/app-navbar/components/NotificationDropdown.vue';
import store from '@/store';
import { onUnmounted, ref } from '@vue/composition-api';
import notificationStore from '@/views/modules/notification/notificationStore';

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    NotificationDropdown,
    // Navbar Components
    DarkToggler,
    BAlert,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    const userData = JSON.parse(localStorage.getItem('userData'));
    return {
      userData,
      isSubscriptionExpired: userData.is_subscription_expired ?? false,
    };
  },
  methods: {
    logout() {
      // Removed token from localStorage.
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem('userData');
      // logout from server

      // Reset ability
      this.$ability.update(initialAbility);

      // Redirect to login page
      this.$router.push({ name: 'auth-login' });
    },
    account() {
      // Redirect to account page
      this.$router.push('/account');
    },
  },
};
</script>
