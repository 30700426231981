<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >
    <!-- Content Left -->
    <b-col
      class="content-header-left mb-2"
      cols="12"
      md="9"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">
            {{ $t(renderText($route.meta.pageTitle)) }}
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in breadcrumb"
                :key="renderText(item.text)"
                :active="item.active"
                :to="item.to"
              >
                {{ $t(renderText(item.text)) }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>

    <!-- Content Right -->
    <b-col
      class="content-header-right text-md-right d-md-block d-none mb-1"
      md="3"
      cols="12"
    />
  </b-row>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol, BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
  },
  data() {
    return {
      breadcrumb: [],
    };
  },
  watch: { $route() { this.updateList(); } },
  mounted() {
    this.$root.$on('breadcrumb', (breadcrumb) => {
      this.breadcrumb = breadcrumb;
    });
    this.updateList();
  },
  methods: {
    updateList() { this.breadcrumb = this.$route.meta.breadcrumb; },
    renderText(text) {
      // we check if its a function otherwise we return the text
      if (typeof text === 'function') {
        return text(this.$route);
      }
      return text;
    },
  },
};
</script>
