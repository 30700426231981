export default [
  {
    title: 'الرئيسية',
    icon: 'HomeIcon',
    resource: 'dashboard',
    action: 'read',
    route: 'home',
  },
  {
    title: 'العروض الترويجية',
    icon: 'PackageIcon',
    resource: 'promotion',
    action: 'read',
    route: 'promotions-list',
  },
  {
    title: 'العملاء',
    icon: 'UsersIcon',
    resource: 'client',
    action: 'read',
    route: 'clients-list',
  },
  {
    title: 'العقود',
    icon: 'FileTextIcon',
    resource: 'contract',
    action: 'read',
    route: 'contracts-list',
    resource: 'contract',
    action: 'read',
  },
  {
    title: 'عروض الأسعار',
    icon: 'FileTextIcon',
    resource: 'quotation',
    action: 'read',
    route: 'quotations-list',
    resource: 'quotation',
    action: 'read',
  },
  {
    title: 'التقييمات',
    icon: 'ClockIcon',
    resource: 'evaluationRequest',
    action: 'read',
    children: [
      {
        title: 'طلبات التّقييم',
        icon: 'Icon',
        resource: 'evaluationRequest',
        action: 'read',
        route: 'evaluationRequests-list',
        resource: 'evaluationRequest',
        action: 'read',
      },
      {
        title: 'التقييمات',
        icon: 'Icon',
        resource: 'evaluation',
        action: 'read',
        route: 'evaluations-list',
      },
      {
        title: 'البحث المتقدم',
        icon: 'Icon',
        resource: 'evaluation',
        action: 'read',
        route: 'evaluations-search',
      },
    ],
  },
  {
    title: 'المستخدمين',
    icon: 'UsersIcon',
    resource: 'user',
    action: 'read',
    children: [
      {
        title: 'قائمة المستخدمين',
        icon: 'Icon',
        resource: 'user',
        action: 'read',
        route: 'users-list',
      },
      {
        title: 'إدارة الأدوار',
        icon: 'Icon',
        resource: 'role',
        action: 'read',
        route: 'roles-list',
      },
    ],
  },
  {
    title: 'طلبات التسجيل',
    icon: 'ClockIcon',
    resource: 'request',
    action: 'read',
    children: [
      {
        title: 'قائمة الطلبات',
        icon: 'Icon',
        route: 'requests-list',
        resource: 'globalSettings',
        action: 'read',
      },
    ],
  },
  {
    header: 'إعدادات النظام',
    resource: 'globalSettings',
    action: 'read',
  },
  {
    title: 'الأماكن',
    icon: 'MapPinIcon',
    resource: 'globalSettings',
    action: 'read',
    children: [
      {
        title: 'المدن',
        icon: 'MapIcon',
        resource: 'globalSettings',
        action: 'read',
        route: 'cities-list',
      },
      {
        title: 'الاحياء',
        icon: 'MapIcon',
        resource: 'globalSettings',
        action: 'read',
        route: 'districts-list',
      },
    ],
  },
  {
    title: 'المساحات',
    icon: 'MapIcon',
    resource: 'globalSettings',
    action: 'read',
    route: 'areas-list',
  },
  {
    title: 'العقار',
    icon: 'MapPinIcon',
    resource: 'globalSettings',
    action: 'read',
    children: [
      {
        title: 'أنواع العقار',
        icon: 'HomeIcon',
        resource: 'globalSettings',
        action: 'read',
        route: 'property-types-list',
      },
      {
        title: 'أصناف العقار',
        icon: 'MapIcon',
        resource: 'globalSettings',
        action: 'read',
        route: 'property-classifications-list',
      },
    ],
  },
  {
    header: 'إعدادات التقييم',
    resource: 'globalSettings',
    action: 'read',
  },
  {
    title: 'المجموعات',
    icon: 'MapIcon',
    resource: 'globalSettings',
    action: 'read',
    route: { name: 'property-feature-groups-list' },
  },
  {
    title: 'معلومات العقار',
    icon: 'MapIcon',
    resource: 'globalSettings',
    action: 'read',
    route: { name: 'property-features-list', params: { id: 'property_info' } },
  },
  {
    title: 'مواصفات العقار',
    icon: 'MapIcon',
    resource: 'globalSettings',
    action: 'read',
    route: {
      name: 'property-features-list',
      params: { id: 'property_description' },
    },
  },
  {
    title: 'التقييم',
    icon: 'BookOpenIcon',
    resource: 'globalSettings',
    action: 'read',
    children: [
      {
        title: 'التسويات',
        icon: 'MapIcon',
        resource: 'globalSettings',
        action: 'read',
        route: {
          name: 'property-features-list',
          params: { id: 'property_settlements' },
        },
      },
      {
        title: 'الملاحظات العامة',
        icon: 'MapIcon',
        resource: 'globalSettings',
        action: 'read',
        route: {
          name: 'property-features-list',
          params: { id: 'property_notes' },
        },
      },
    ],
  },
  {
    title: 'معلومات أخرى',
    icon: 'MapIcon',
    resource: 'globalSettings',
    action: 'read',
    route: { name: 'property-features-list', params: { id: 'other_info' } },
  },
  {
    title: 'الإعدادات',
    icon: 'HomeIcon',
    resource: 'company',
    action: 'update',
    children: [
      {
        title: 'الإشتراك',
        icon: '',
        resource: 'invoice',
        action: 'read',
        route: 'invoices-list',
        resource: 'invoice',
        action: 'read',
      },
      {
        title: ' إعدادات الشركة',
        icon: '',
        route: 'account-setting',
        resource: 'company',
        action: 'update',
      },

      // {
      //   title: 'إعدادات الحساب',
      //   icon: 'Icon',
      //   resource: 'user',
      //   action: 'update',
      //   route: 'users-add',
      //   resource: 'user',
      //   action: 'update',

      // },
    ],
  },
  {
    header: 'إعدادات التقارير',
    resource: 'evaluation',
    action: 'read',
  },
  {
    title: 'التقارير',
    icon: 'BookOpenIcon',
    resource: 'evaluation',
    action: 'read',
    children: [
      {
        title: 'التقارير العامة',
        icon: 'MapIcon',
        resource: 'evaluation',
        action: 'read',
        route: {
          name: 'reports-list',
        },
      },
      {
        title: 'صفحات التقارير',
        icon: 'MapIcon',
        resource: 'evaluation',
        action: 'read',
        route: {
          name: 'pages-list',
        },
      },
      {
        title: 'نماذج التقارير',
        icon: 'MapIcon',
        resource: 'evaluation',
        action: 'read',
        route: {
          name: 'templates-list',
        },
      },
      {
        title: 'نماذج البحث الفعّالة',
        icon: 'MapIcon',
        resource: 'evaluation',
        action: 'read',
        route: {
          name: 'research-models-list',
        },
      },
    ],
  },
];
